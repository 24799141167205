/*----------------------------------------*/
/*  04. Page CSS
/*----------------------------------------*/


@import "page/slider";
@import "page/service";
@import "page/call-to-action";
@import "page/why-choose";
@import "page/cases";
@import "page/brand";
@import "page/customer";
@import "page/blog";
@import "page/page-banner";
@import "page/about";
@import "page/video";
@import "page/consultant";
@import "page/services-details";
@import "page/blog-details";
@import "page/cases-details";
@import "page/faq";
@import "page/contact";
@import "page/404-error";








